<template>
  <div class="checkout-order p-1" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">
        <span v-if="currentStatus === 'preCheckout'">結單</span>
        <span v-else>配單結單</span>
      </p>
    </div>
    <div class="tw-container p-1">
      <!-- 按鈕 -->
      <div class="mb-3">
        <button
          class="tw-btn tw-btn-secondary me-3"
          @click="showModal('soldOut')"
        >
          顯示斷貨
        </button>
        <button
          v-if="customerCanEditCheckoutQuantity"
          class="tw-btn tw-btn-success me-3"
          @click="updateCheckoutQuantity('multiple', 'min')"
        >
          全數歸0
        </button>
        <button
          v-if="customerCanEditCheckoutQuantity"
          class="tw-btn tw-btn-success"
          @click="updateCheckoutQuantity('multiple', 'max')"
        >
          全數結單
        </button>
      </div>
      <!-- 訂單列表 -->
      <div class="table-responsive mb-3" style="height: 400px">
        <table class="table table-hover">
          <thead class="float-header">
            <tr>
              <th>封面</th>
              <th class="text-start" style="min-width: 150px">商品</th>
              <th class="text-start" style="min-width: 150px">款式</th>
              <th>數量</th>
              <th style="min-width: 80px">原單價/目前單價</th>
              <th>已結</th>
              <th style="min-width: 80px">已配/未配</th>
              <th style="min-width: 200px">結單</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="merchOrders.size > 0">
              <template v-for="data in merchOrders" :key="data[0]">
                <tr v-for="item in data[1].merchOrders" :key="item.id">
                  <td>
                    <Image
                      :imageHash="data[1].frontImageHash"
                      size="s"
                      v-if="data[1].frontImageHash"
                      :alt="data[1].frontImageHash"
                      style="width: 50px; height: 50px"
                    ></Image>
                    <img
                      src="@/assets/other-images/noImg.png"
                      v-else
                      style="width: 50px; height: 50px"
                    />
                  </td>
                  <td class="text-start">
                    {{ data[1].merchandiseName }}
                  </td>
                  <td class="text-start">
                    <span
                      v-for="style in item.merchandiseStyles"
                      :key="style.id"
                    >
                      {{ style.name }}&ensp;
                    </span>
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>
                    <p class="mb-2">
                      {{ item.price }} / {{ item.currentPrice }}
                    </p>
                    <p
                      class="text-success fw-bolder"
                      v-if="item.price > item.currentPrice"
                    >
                      符合多件優惠，故單價降至多件優惠價格
                    </p>
                    <p
                      class="text-secondary fw-bolder"
                      v-else-if="item.price < item.currentPrice"
                    >
                      原單價小於等於優惠價格，故保持原單價
                    </p>
                  </td>
                  <td>{{ item.checkOutQuantity }}</td>
                  <td>
                    {{ item.allocatedQuantity }} /
                    {{ item.quantity - item.allocatedQuantity }}
                  </td>
                  <!-- 結單數量調整 -->
                  <td v-if="customerCanEditCheckoutQuantity">
                    <button
                      class="tw-btn tw0btn-secondary"
                      @click="updateCheckoutQuantity('single', 'min', item)"
                    >
                      MIN
                    </button>
                    <input
                      onwheel="this.blur()"
                      class="w-25"
                      type="number"
                      min="0"
                      @input="listenCheckOutInput"
                      v-model="item.currentCheckOutQty"
                      pattern="[0-9]*"
                      oninput="this.value=this.value.replace(/\D/g,'')"
                    />
                    <span>
                      / {{ item.quantity - item.checkOutQuantity }}&ensp;</span
                    >
                    <button
                      class="tw-btn tw0btn-secondary"
                      @click="updateCheckoutQuantity('single', 'max', item)"
                    >
                      MAX
                    </button>
                  </td>
                  <td v-else>{{ item.currentCheckOutQty }}</td>
                </tr>
              </template>
            </template>
            <tr v-else>
              <td colspan="8" class="text-danger fw-bolder text-center">
                此顧客目前無任何訂單可以結單
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 結單資訊 -->
      <CheckoutInfo
        :participantId="participantId"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromCheckoutInfoData="fromCheckoutInfoData"
      ></CheckoutInfo>
      <!-- 發票資訊 -->
      <ReceiptInfo
        v-if="!p_loading && customerCheckoutCanSetReceiptInfo"
        :propsToReceiptInfo="propsToReceiptInfo"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromReceiptInfoData="fromReceiptInfoData"
      ></ReceiptInfo>
      <!-- 結單優惠 -->
      <Promotions
        v-if="storeInfo.allowCustomerDiscount"
        :participantId="participantId"
        @fromPromotionsData="fromPromotionsData"
      ></Promotions>
      <div class="row">
        <!-- 帳單平衡 -->
        <div class="col-md-6 p-0 mb-md-0 mb-5">
          <!-- <Balance @fromBalanceData="fromBalanceData"></Balance> -->
        </div>
        <!-- 金額 -->
        <div class="col-md-6 p-0">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">金額</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費:
                {{
                  $methods.numberToFixed(propsToCheckoutInfo.shippingCost)
                    ? $methods.numberToFixed(propsToCheckoutInfo.shippingCost)
                    : 0
                }}元
              </li>
              <!-- <li class="mb-2">帳單平衡: {{ balanceTotal }}元</li> -->
              <li class="mb-2">
                訂單金額: {{ $methods.numberToFixed(merchOrderTotalPrice) }}元
              </li>
              <li class="mb-2" v-if="storeInfo.allowCustomerDiscount">
                結單優惠:
                <span class="text-success" v-if="finalStoreDiscount">
                  <span v-if="finalStoreDiscount.discountAmount">
                    {{ finalStoreDiscount.discountName }}(折扣:{{ finalStoreDiscount.discountAmount }}元)
                  </span>
                  <del class="text-secondary" v-else >
                    {{ finalStoreDiscount.discountName }}(折扣:{{ finalStoreDiscount.discountAmount}}元)
                  </del>
                </span>
                <span class="text-danger" v-else>無套用結單優惠</span>
              </li>
              <li>
                購物金折抵:
                {{ $methods.numberToFixed(propsToCheckoutInfo.money) ? $methods.numberToFixed(propsToCheckoutInfo.money) : 0 }}元
              </li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額: {{ $methods.numberToFixed(finalTotal) }}元
              </p>
              <div class="form-check p-0" v-if="!p_loading && showDetail">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  value=""
                  id="ShowDetail"
                  v-model="d4showDetail"
                />
                <label class="form-check-label fw-bolder" for="ShowDetail">
                  代客直寄
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="text-end">
      <button class="tw-btn tw-btn-secondary me-3" @click="goBack">返回</button>
      <button class="tw-btn tw-btn-success" @click="createBill(true)">
        完成
      </button>
    </div>
  </div>
  <!-- 斷貨的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="soldOutModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">已斷貨訂單資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>訂單編號</th>
                <th class="text-start">商品</th>
                <th class="text-start">款式</th>
                <th>斷貨數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in soldOutList" :key="data.id">
                <td>
                  {{ data.id }}
                </td>
                <td class="text-start">
                  {{ data.merchandise.name }}
                </td>
                <td class="text-start">
                  <span v-for="style in data.merchandiseStyles" :key="style.id">
                    {{ style.name }}&ensp;
                  </span>
                </td>
                <td>{{ data.soldOutQuantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import CheckoutInfo from '../../../components/checkoutOrder/CheckoutInfo.vue'
import Promotions from '../../../components/checkoutOrder/Promotions.vue'
import Balance from '../../../components/checkoutOrder/Balance.vue'
import ReceiptInfo from '../../../components/checkoutOrder/ReceiptInfo.vue'

// service
import { countStoreDiscount } from '../../../methods/service/storeDiscountService'

export default {
  components: {CheckoutInfo, Promotions, Balance, ReceiptInfo},
  data() {
    return {
      // modal
      soldOutModal: {},
      // data
      serverToken: '',
      storeId: 0,
      participantId: 0,
      currentStatus: 'preCheckout',
      participantInfo: {},
      merchOrders: [],
      soldOutList: [],
      storeInfo: null,
      // 傳給子層(出貨付款資訊)
      propsToCheckoutInfo: {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: 0,
      },
      // 傳給子層(發票資訊)
      propsToReceiptInfo: {
        title: '',
        number: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
      },
      // 結單優惠傳來的資訊
      selectStoreDiscount: null,
      // 最後計算完的結單優惠結果
      finalStoreDiscount: null,
      // 帳單平衡過來的訊息
      balances: [],
      balanceTotal: 0,
      // 訂單總金額
      merchOrderTotalPrice: 0,
      // 結單總數
      totalCheckoutQuantity: 0,
      // 折扣金額 (結單優惠)
      discountAmount: 0,
      // 最終金額
      finalTotal: 0,
      // 代客直寄
      d4showDetail: false
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
    this.getParticipantInfo()
  },
  mounted() {
    this.createModals(['soldOutModal'])
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 顧客可自行調整結單數量
    customerCanEditCheckoutQuantity() {
      return this.$store.state.Prefer.p_store.customerCanEditCheckoutQuantity[0].selected
    },
    // 偏好 > 結單可填寫發票資訊
    customerCheckoutCanSetReceiptInfo() {
      return this.$store.state.Prefer.p_store.customerCheckoutCanSetReceiptInfo[0].selected
    },
    // 偏好 > 代客直寄
    showDetail() {
      return this.$store.state.Prefer.p_store.showDetail[0].selected
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.participantId = this.$route.query.participantId
      this.currentStatus = this.$route.query.status
    },
    showModal(status) {
      if (status === 'soldOut') {
        // 顯示斷貨
        this.soldOutModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 檢查賣場是否可以自行結單
            if (!storeInfo.canCheckOut) {
              alert('此賣場未開放顧客可自行結單')
              vm.$methods.switchLoading('hide')
              return vm.goBack()
            }
            vm.storeInfo = storeInfo
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得顧客資訊
    getParticipantInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 13,
        ids: [this.participantId],
        methods: '{getMerchOrders,getCustomerGroup}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.participantInfo = participantInfo
            let merchOrderIds = []
            participantInfo.merchOrders.forEach(item => {
              merchOrderIds.push(item.id)
            })
            vm.getMerchOrders(merchOrderIds)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得訂單
    getMerchOrders(merchOrderIds) {
      const vm = this
      const getMerchOrdersApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 26,
        ids: merchOrderIds,
        methods: '{getMerchandise{getMerchandisePictureLinks},getShippingQuantity,getCheckOutQuantity,getMerchandiseStyles{getMerchandiseDiscounts}}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchOrdersApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrderList = res.data[0].objects
            vm.sortOutData(merchOrderList)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理資料
    sortOutData(merchOrderList) {
      this.$methods.switchLoading('show')
      // 整理訂單
      this.merchOrders = []
      this.soldOutList = []
      let merchOrdersMap = new Map()
      merchOrderList.forEach(item => {
        console.log(item)
        // 斷貨
        if (item.soldOutQuantity > 0) this.soldOutList.push(item)
        // 預購結單/配單結單
        if (item.quantity > 0 && item.quantity > item.checkOutQuantity && item.merchandise.checkOut) {
          if (!merchOrdersMap.has(item.merchandiseStyles[0].id)) {
            if (this.currentStatus === 'preCheckout') {
              // 預購結單
              let object = {
                frontImageHash: null,
                merchandiseName: item.merchandise.name,
                merchandiseStyles: item.merchandiseStyles,
                discounts: item.merchandiseStyles[0].merchandiseDiscounts,
                merchOrders: [],
              }
              item.merchandise.merchandisePictureLinks.some(item => {
                if (item.front) return object.frontImageHash = item.imageHash
              })
              object.merchOrders = [{
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
              }]
              merchOrdersMap.set(item.merchandiseStyles[0].id, object)
            } else if (this.currentStatus === 'allocatedCheckout' && item.allocatedQuantity - item.shippingQuantity > 0 && item.allocatedQuantity > item.checkOutQuantity) {
              // 配單結單
              let object = {
                frontImageHash: null,
                merchandiseName: item.merchandise.name,
                merchandiseStyles: item.merchandiseStyles,
                discounts: item.merchandiseStyles[0].merchandiseDiscounts,
                merchOrders: [],
              }
              item.merchandise.merchandisePictureLinks.some(item => {
                if (item.front) return object.frontImageHash = item.imageHash
              })
              object.merchOrders = [{
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
              }]
              merchOrdersMap.set(item.merchandiseStyles[0].id, object)
            }
          } else {
            if (this.currentStatus === 'preCheckout') {
              // 預購結單
              merchOrdersMap.get(item.merchandiseStyles[0].id).merchOrders.push({
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
              })
            } else if (this.currentStatus === 'allocatedCheckout' && item.allocatedQuantity > 0) {
              // 配單結單
              merchOrdersMap.get(item.merchandiseStyles[0].id).merchOrders.push({
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
              })
            }
          }
        }
      });
      console.log(merchOrdersMap)
      this.merchOrders = merchOrdersMap
      this.updateCheckoutQuantity('multiple', 'max')
      // 傳遞資料到子層 <出貨付款資訊>
      this.propsToCheckoutInfo = {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: this.participantInfo.wallet,
        shopNumber: '',
        shopName: ''
      }
      this.$methods.switchLoading('hide')
    },
    // 監聽結單輸入框
    listenCheckOutInput() {
      this.countMerchOrderTotalPrice()
    },
    // max / min
    updateCheckoutQuantity(mode, status, item) {
      if (mode === 'single') {
        if (status === 'min') {
          item.currentCheckOutQty = 0
        } else {
          if (this.currentStatus === 'allocatedCheckout') item.currentCheckOutQty = Math.max(item.allocatedQuantity - item.checkOutQuantity, 0)
          else item.currentCheckOutQty = item.quantity - item.checkOutQuantity
        }
      } else {
        if (status === 'min') {
          for (const [key, value] of this.merchOrders) {
            value.merchOrders.forEach(item => {
              item.currentCheckOutQty = 0
            })
          }
        } else {
          for (const [key, value] of this.merchOrders) {
            value.merchOrders.forEach(item => {
              if (this.currentStatus === 'allocatedCheckout') item.currentCheckOutQty = Math.max(item.allocatedQuantity - item.checkOutQuantity, 0)
              else item.currentCheckOutQty = item.quantity - item.checkOutQuantity
            })
          }
        }
      }
      this.countMerchOrderTotalPrice()
    },
    // 來自出貨付款的訊息 (子層 > 父層)
    fromCheckoutInfoData(object) {
      this.propsToCheckoutInfo = object
      this.countMerchOrderTotalPrice()
    },
    // 來自結單優惠的訊息 (子層 > 父層)
    fromPromotionsData(object) {
      this.selectStoreDiscount = object
      this.countMerchOrderTotalPrice()
    },
    // 來自帳單平衡的訊息 (子層 > 父層)
    fromBalanceData(array) {
      this.balances = array
      this.countMerchOrderTotalPrice()
    },
    // 來自發票資訊的訊息 (子層 > 父層)
    fromReceiptInfoData(object) {
      this.propsToReceiptInfo = object
    },
    // 計算訂單總價 (含多件優惠)
    countMerchOrderTotalPrice() {
      console.log('計算訂單總價...')
      // 訂單總價
      this.merchOrderTotalPrice = 0
      // 最後加上結單優惠的訂單總價
      this.hasStoreDiscountMerchOrderTotalPrice = 0
      // 總共買幾件
      this.totalCheckoutQuantity = 0
      // 套用結單優惠會折抵多少
      this.discountAmount = 0
      const participantVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      for (const [key, value] of this.merchOrders) {
        // 紀錄總數量
        let totalQuantity = 0
        // 紀錄最便宜的價格
        let cheapPrice = value.merchandiseStyles[0].price
        value.merchOrders.forEach((item, index) => {
          const canCheckoutQuantity = item.quantity - item.checkOutQuantity
          let currentCheckOutQty = item.currentCheckOutQty ? item.currentCheckOutQty : 0
          if (currentCheckOutQty > canCheckoutQuantity) {
            currentCheckOutQty = canCheckoutQuantity
            item.currentCheckOutQty = canCheckoutQuantity
          }
          totalQuantity += currentCheckOutQty
          this.totalCheckoutQuantity += currentCheckOutQty
          // 代表已經到迴圈最後了，可以開始計算最終價格
          if (index === value.merchOrders.length - 1) {
            if (value.discounts) {
              value.discounts.forEach(discount => {
                const minQuantity = discount.minQuantity
                const maxQuantity = discount.maxQuantity
                const discountVipId = discount.storeVipId ? discount.storeVipId : 0
                let inPurchaseInterval = false
                if (minQuantity && maxQuantity) inPurchaseInterval = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
                else inPurchaseInterval = minQuantity <= totalQuantity
                const idVipInterval = participantVipId >= discountVipId
                // 先找出最優惠的價格
                if (inPurchaseInterval && idVipInterval && cheapPrice > discount.price) cheapPrice = discount.price
              })
            }
          }
        })
        // 再將最優惠的價格跟訂單原先買的價格做比對 (todo 在這邊做文字呈現)
        value.merchOrders.forEach(item => {
          if(item.price > cheapPrice) item.currentPrice = cheapPrice
          else item.currentPrice = item.price
          this.merchOrderTotalPrice += item.currentPrice * item.currentCheckOutQty
        })
      }
      this.hasStoreDiscountMerchOrderTotalPrice = this.merchOrderTotalPrice
      this.countFinalTotal()
    },
    // 計算總價
    countFinalTotal() {
      console.log('計算總金額...')
      this.finalTotal = 0
      const shippingCost = this.propsToCheckoutInfo.shippingCost ? this.$methods.numberToFixed(this.propsToCheckoutInfo.shippingCost) : 0
      const merchOrderTotal = this.$methods.numberToFixed(this.hasStoreDiscountMerchOrderTotalPrice)
      const money = this.propsToCheckoutInfo.money ? this.$methods.numberToFixed(this.propsToCheckoutInfo.money) : 0
      let balance = 0
      this.balances.forEach(item => {
        const money = item.money ? item.money : 0
        balance += money
      })
      this.balanceTotal = this.$methods.numberToFixed(balance)
      
      // 若有結單優惠
      this.finalStoreDiscount = null;
      if (this.selectStoreDiscount) this.finalStoreDiscount = countStoreDiscount(this.selectStoreDiscount, merchOrderTotal, shippingCost, this.totalCheckoutQuantity);
    
      const discountAmount = this.finalStoreDiscount ? this.finalStoreDiscount.discountAmount : 0;
      this.finalTotal = this.$methods.numberToFixed(shippingCost + merchOrderTotal - money + balance - discountAmount)
    },
    // 建立訂單
    createBill:_.debounce(function(notify) {
      this.$methods.switchLoading('show')
      const vm = this
      const createBillApi = `${process.env.VUE_APP_API}/bill/create`
      const header = {
        authorization: this.serverToken
      }
      let note = ''
      if (this.propsToCheckoutInfo.shopNumber) note += `門市編號: ${this.propsToCheckoutInfo.shopNumber}\n`
      if (this.propsToCheckoutInfo.shopName) note += `門市名稱: ${this.propsToCheckoutInfo.shopName}\n`
      if (this.propsToCheckoutInfo.note) note += this.propsToCheckoutInfo.note
      const billHeader = {
        storeId: this.storeId,
        buyerId:this.participantId,
        note: note,
        shippingType: this.propsToCheckoutInfo.selectShppingType ? this.propsToCheckoutInfo.selectShppingType.split(',')[0] : null,
        shippingCost: this.propsToCheckoutInfo.shippingCost ? this.propsToCheckoutInfo.shippingCost : 0,
        recipient: this.propsToCheckoutInfo.name,
        recipientPhoneNumber: this.propsToCheckoutInfo.phone ? this.propsToCheckoutInfo.phone : null,
        recipientPostalCode: this.propsToCheckoutInfo.selectArea ? this.propsToCheckoutInfo.selectArea : null,
        receivingAddress: this.propsToCheckoutInfo.address,
        paymentType: this.propsToCheckoutInfo.selectPaymentType ? this.propsToCheckoutInfo.selectPaymentType : null,
        walletUseAmount: this.propsToCheckoutInfo.money ? this.propsToCheckoutInfo.money : 0,
        receiptTitle: this.propsToReceiptInfo.title ? this.propsToReceiptInfo.title : null,
        receiptBusinessAdminNumber: this.propsToReceiptInfo.number ? this.propsToReceiptInfo.number : null,
        receiptPhoneNumber: this.propsToReceiptInfo.phone ? this.propsToReceiptInfo.phone : null,
        receiptPostalCode: this.propsToReceiptInfo.selectArea ? this.propsToReceiptInfo.selectArea : null,
        receiptAddress: this.propsToReceiptInfo.address ? this.propsToReceiptInfo.address : null,
        showDetail: this.d4showDetail,
        discountName: this.finalStoreDiscount && this.finalStoreDiscount.discountAmount !== 0 ? this.finalStoreDiscount.discountName : null,
        discountType: this.finalStoreDiscount && this.finalStoreDiscount.discountAmount !== 0 ? this.finalStoreDiscount.discountType : null,
        discountAmount: this.finalStoreDiscount && this.finalStoreDiscount.discountAmount !== 0 ? this.finalStoreDiscount.discountAmount : null,
      }
      let bodies = []
      for (const [key, value] of this.merchOrders) {
        value.merchOrders.forEach(item => {
          if (item.currentCheckOutQty > 0) {
            bodies.push({
              merchOrderId: item.id,
              price: item.currentPrice,
              quantity: item.currentCheckOutQty
            })
          }
        })
      }
      let balances = []
      this.balances.forEach(item => {
        const money = item.money ? item.money : 0
        if (money !== 0) {
          balances.push({
            description: item.reson ? item.reson : null,
            money: item.money
          })
        }
      })
      const data = {
        header: billHeader,
        bodies: bodies,
        balances: balances,
        notify: notify
      }
      console.log(data)
      // 若與到系統卡頓讓他有機會再點第二次話就擋住
      if (this.checkAjaxQueue("/bill/create", 1)) return this.SweetAlert("other", "正在建立帳單中...請稍後")
      console.log(this.recordAjax)
      const recordAjax = $.ajax({
        type: 'POST',
        async: true,
        url: createBillApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.$router.push(`/customer/store/${vm.storeId}/merchOrder`)
          }
          vm.removeAjaxQueue("/bill/create", 1)
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON.code === '400-6' && err.responseJSON.message === '商品成團數不足') {
            let errorMessage = `${err.responseJSON.message}: `
            err.responseJSON.errorData.forEach(item => {
              errorMessage += `${item.merchandise.name}: 目前累計: ${item.accumulatedQuantity}單 `
            })
            vm.SweetAlert(err.responseJSON.code, errorMessage)
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
          vm.removeAjaxQueue("/bill/create", 1)
          vm.$methods.switchLoading('hide')
        },
      }, 2000, {
        leading: true, // 在延遲開始前，立即調用
        trailing: false, // 在延遲結束前，保證不再調用
      })
      this.addAjaxQueue("/bill/create", 1, recordAjax)
    }),
    // 返回
    goBack() {
      this.$router.push({name: 'C_MerchOrder'})
    },
  },
}
</script>